
ul{
    text-decoration: none;
}
li a{
    text-decoration-line: none
}


.footer-links {
    display: flex;
    position: relative;
    color: rgb(255, 255, 255);
    font-family: "Titillium Web", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-out;
    margin-bottom: 0;
    position: relative;
   
  }
  .footer-links:hover {
    padding: auto;
    color: rgb(255, 255, 255);
    font-family: "Titillium Web", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.2s ease-out;
  }
  
  .footer-links::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fff005;
    transition: width 0.5s ease, left 0.5s ease; 
  }
  
  .footer-links:hover::after {
    width: 80%;
  }
  
  .footer-links:not(:hover)::after {
    width: 0; 
  }
  



/* Color del Footer*/
.color__footer{
    background-color: #2c2929;
    padding-top: 2rem;
    margin: auto;
}



/* Contenedor Principal*/
.footer__container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 3rem;
    font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important;
  
}


/*Contenedor del Logo */
.footer__Logo{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 320px;
}
.footer__logo__img {
    display: flex;
    justify-content: center;
}

.footer__logo__img img {
    margin-top: 0.2em;
    height: 75%;
    width: 8rem;
    margin-left: 0rem;
}
.footer__navbar__logo:hover {
  cursor: pointer;
}

/* Iconos Redes Sociales*/
.footer__logo__IconsSocial{
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2rem;
}
.footer__logo__IconsSocial__item{
    color: #ffffff;
    width: 16px;
    height: 16px;
    margin-right: 1.5rem;
    transition: all .25s ease-in-out;
}
.footer__logo__IconsSocial__item:hover{
    cursor: pointer;
    color: #fbff1d;
    transform: scale(1.4);
}



/* Contenedor Principal de Informacion Footer */
.footer__info{
    display: flex;
    justify-content: center;
    flex-direction: column;
}


/* Contenedor Categorias productos */
.footer__info__products{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.footer__info__products__list{
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.footer__info__products__item{
    display: flex;
    text-align: center;
    text-decoration: none;
    font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1.2px;
    /*text-transform: uppercase;*/
    margin-top: 0.8rem;
}
.footer__info__products__item-btn{
    display: flex;
    position: relative;
    text-align: center;
    text-decoration: none;
    font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding: auto;
    margin-left: 0.5rem;
}
.footer__span{
    padding: 1.2rem;
    margin-left: 5px;
    margin-right: 5px;
    transition: all .25s ease-in-out;
    color: rgb(255, 255, 255);
}
.footer__span:hover{
    color:#278d0a;
    cursor: pointer;
}


/* Contenedor informacion Contacto */
.footer__info__contact{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}
.footer__info__contact__list{
    display: block;
    width: 100%;
    justify-content: center;
}
.footer__info__contact__listbtn{
    display: block;
    width: 100%;
    justify-content: center;
}
.footer__info__contact__item{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0.5rem;
}
.footer__info__contact__iconText{
    display: inline-flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2.5rem;
}
.footer__info__contact__iconTextbtn{
    display: inline-flex;
    flex-direction: row;
    margin-top: 1.5rem;
}
.footer__icon__info{
    display: flex;
    font-size: 21px;
    color: #278d0a;
    margin-top: 0.1rem;
}

.icon-Location-Footer{
    display: flex;
    font-size: 1.5rem;
    color: #ffffff;
}

.footer__contact__span{
    display: flex;
    width: 400px;
    margin-left: 1rem;
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 400;
    font-family: "Titillium Web", sans-serif;
    font-size: 1rem;
    color: #ffffff;
}

/*Estilo boton footer*/
.footer__info__contact__item a{
    text-decoration-line: none;
}
.footer__btn__outline{
    display: flex;
    justify-content: center;
    font-size: 13px;
    background-color: transparent;
    padding: 0.7rem 2rem;
    border: 1px solid #47A01A;
    transition: all 0.3s ease-out;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
    font-family: "Montserrat";
    font-weight: 500;  
}
.footer__btn__outline:hover{
    font-size: 13px;
    color: rgb(255, 255, 255);
    background-color: #47A01A;
    border: 1px solid #47A01A;
}


/**/
.text-center{
    display: flex;
    justify-content: center;
    margin: auto;
}
.text-Copy{
    color: #fff !important;
    background-color: #000000;
    letter-spacing: 0px;
    font-family: "Gotham";
    font-size: 11px;
    padding: 0.4rem;
}

.btn-Incripción-footer{
    display: inline-block;
    padding: 9px 20px;
    font-size: 18px;
    border-radius: 2px;
    text-decoration-line: none;
    outline: none;
    border: 2px solid  #ffffff;
    background-color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
    margin: 0px;
    font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important;
    font-size: 0.85rem;
    font-weight: 500;
    color: #242222;
    transition: all 0.3s ease-out;
  }
  
  .btn-Incripción-footer:hover{
    display: inline-block;
    border: 2px solid  #ffd800;
    background-color: #ffd800;
    color: #242222;
    transition: all 0.3s ease-out;
  }






@media screen and (max-width: 960px) {

    /*Contenedor del Logo */
.footer__Logo{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: 100%;
    margin-left: 0rem;
}
.footer__logo__img img {
    margin-top: 0.2em;
    margin-left: 2.5rem;
    margin-bottom: 0rem;
    width: 7rem;
}


/* Contenedor Principal*/
.footer__container{
display: flex;
justify-content: center;
flex-direction: column;
width: 100%;
padding: 0rem;
padding-bottom: 2rem;
}

.footer__logo__IconsSocial{
    margin-left: 3rem;
}

/* Contenedor Principal de Informacion Footer */
.footer__info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 2rem;
}


/* Contenedor Categorias productos */
.footer__info__products{
    display: grid;
    width: 100%;
}
.footer__info__products__list{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding: 0;
    list-style: none;
    margin-bottom: 2rem;
}
.footer__info__products__item{
    display: flex;
    width: 100%;
    text-align: center;
    text-decoration-line: none; 
    text-decoration: none;
    list-style: none; 
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.footer__span{
    padding: 1.2rem;
    margin-left: 5px;
    margin-right: 5px;
    transition: all .25s ease-in-out;
    text-decoration: none;
}
.footer__span:hover{
    color:#47A01A;
    cursor: pointer;
}


.footer__info__products__item-btn{
    display: flex;
    margin-top: 0.8rem;
}





/* Contenedor informacion Contacto */
.footer__info__contact{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.footer__info__contact__list{
    display: block;
    width: 100%;
    justify-content: center;
}
.footer__info__contact__listbtn{
    display: block;
    width: 100%;
    justify-content: center;
}
.footer__info__contact__item{
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 0.5rem;
}
.footer__info__contact__iconText{
    display: inline-flex;
    flex-direction: row;
    margin-top: 1.5rem;
}
.footer__info__contact__iconTextbtn{
    display: inline-flex;
    flex-direction: row;
    margin-top: 2rem;
}
.footer__icon__contact{
    display: flex;
    width: 15px;
    height: 15px;
    color: #47A01A;
    margin-top: 0.1rem;
}
.footer__contact__span{
    display: flex;
    width: 300px;
    margin-left: 1rem;
    font-size: 12px;
}
.footer__contact__span:hover{
    cursor: pointer;
}





}