*{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }


/* Fondo */
.backgroundColor1{
  background-color: #42ac35;
}

.backgroundColor2{
  background-color: #edf2f0;
}

.backgroundDark{
  background-color: #269105;
}

.backgroundAmarillo{
  background-color: #f9faf0;
}

.backgroundColorNavbar{
  background-color: #fffeea;
}

.yellow__Miceven{
  color: #269105;
  color: #084e1a;
}

/* Titulo 1 */
.title__home{
  text-align: start;
  align-items: center;
  color: #4a770c;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 55px;
  line-height: 68px;
  letter-spacing: 0px;
}




.title__home__two{
  text-align: start !important;
  align-items: center;
  color: #434b41;
  font-family: "Mulish", sans-serif !important;
  font-weight: 600;
  font-size: 57px;
  line-height: 65px;
  letter-spacing: 0.3px;
  text-transform: uppercase; /* Add this line */
}


.title__home__Protest{
  text-align: start !important;
  align-items: center;
  color: #269105;
  font-family: 'Protest Strike', sans-serif !important;
  font-weight: 400;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: 1.5px;
}

.title__home__Protest-center{
  text-align: center !important;
  align-items: center;
  color: #269105;
  font-family: 'Protest Strike', sans-serif !important;
  font-weight: 400;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: 1.5px;
}


.title__home__Protest-center-dark{
  text-align: center !important;
  align-items: center;
  color: #096a01 !important;
  font-family: 'Protest Strike', sans-serif !important;
  font-weight: 400;
  font-size: 62px;
  line-height: 70px;
  letter-spacing: 1.5px;
}


.title__home__Protest-Border {
  text-align: center;
  align-items: center;
  color: #269105;
  font-family: 'Protest Strike', sans-serif !important;
  font-size: 65px;
  line-height: 68px;
  letter-spacing: 1.5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0.5rem;
  background-color: #f0c612;
  display: inline-block;
}

.title__home__Protest-Border-start {
  text-align: start;
  align-items: center;
  color: #269105;
  font-family: 'Protest Strike', sans-serif !important;
  font-size: 65px;
  line-height: 68px;
  letter-spacing: 1.5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0.5rem;
  background-color: #f0c612;
  display: inline-block;
}




.title__home__White{
  text-align: start !important;
  align-items: center;
  color: #ffffff;
  font-family: 'Protest Strike', sans-serif !important;
  font-weight: 500;
  font-size: 62px;
  line-height: 68px;
  letter-spacing: 0.7px;
}



/* Subtitulo */
.subtitle__home{
  text-align: start;
  align-items: center;
  color: #3F4A3C;
  /*color: #fec602;*/
  font-family: "Titillium Web", sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.5px;
}














/*Colores*/
/*Theme and Colors*/

.main-green{
  color: #47A01A;
}
.secondary-green{
  color: #1F4931;
}




  /*Fuentes*/

  .Montserrat-Font{
    font-family: 'Gotham', sans-serif;
  }
  .Gothan-Font{
    font-family: 'Gotham', sans-serif;
  }
 


   





  @media only screen and (max-width: 960px) {


    .title__home__two{
      text-align: center !important;
      align-items: center !important;
      color: #0c4401;
      font-family: 'Protest Strike', sans-serif !important;
      font-weight: 500;
      font-size: 45px;
      line-height: 49px;
      letter-spacing: 0.7px;
    }

    .title__home__Protest{
      text-align: center !important;
      align-items: center;
      color: #269105;
      font-family: 'Protest Strike', sans-serif !important;
      font-weight: 400;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 0.5px;
    }

    .title__home__Protest-center{
      text-align: center !important;
      align-items: center;
      color: #269105;
      font-family: 'Protest Strike', sans-serif !important;
      font-weight: 400;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 0.5px;
    }

    .title__home__Protest-center-dark{
      text-align: center !important;
      align-items: center;
      color: #096a01 !important;
      font-family: 'Protest Strike', sans-serif !important;
      font-weight: 400;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 0.5px;
    }
   
    
    .title__home__Protest-Border {
      text-align: center;
      align-items: center;
      color: #269105;
      font-family: 'Protest Strike', sans-serif !important;
      font-size: 40px;
      line-height: 45px;
      letter-spacing: 0.5px;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 0.5rem;
      background-color: #f0c612;
      display: inline-block;
    }












    .title__home__White{
      text-align: center !important;
      align-items: center !important;
      font-family: 'Protest Strike', sans-serif !important;
      font-weight: 500;
      font-size: 45px;
      line-height: 49px;
      letter-spacing: 0.7px;
    }
    

    /* Subtitulo */
    .subtitle__home{
      text-align: center !important;
      align-items: center;
      /*color: #fec602;*/
      font-family: "Titillium Web", sans-serif;
      font-size: 26px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.5px;
      text-decoration-color: #e7eb22;
    }

  }