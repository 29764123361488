/*Contenedor Principal*/

.container__Contact__Main{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
}
.container__Contact{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 20px;
}
.container__Form{
  display: flex;
  display: flex;
  align-items: center;
    justify-content: center;
    width: 43rem;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 20px;
}



.title-container-contact{
  display: flex;
  justify-content: center;
}


.btn-container{
  display: flex;
  justify-content: center;
  margin-top: 2rem;

}





.imgContainer-Contact{
  display: flex;
  width: 33rem;
  justify-content: center;
  padding-top: 2rem;
}


.img-Contact{
  transition: scale 0.3s ease;

}


.img-Contact:hover{
  scale: 1.1;
  cursor: pointer;
}






  @media screen and (max-width: 960px) {

    .container__Contact__Main{
      width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 0rem;
        padding-bottom: 3rem;
    }

    .container__Contact{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-bottom: 5px;
  }


  .imgContainer-Contact {
    display: flex;
    width: 70%;
    justify-content: center;
    padding-top: 0rem;
    margin-top: 2rem;
    height: 70%;
  }




  .container__Form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 20px;
  }





  .img-Contact {
    display: flex;
  }



  


}