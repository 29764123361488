.container-Banner-Main-Movil{
    display:none;
}
.container-Banner-Main{
    display:flex;
}

@media screen and (max-width: 960px) {
    .container-Banner-Main-Movil{
        display:flex;
        padding-top: 0px;
    }
    .container-Banner-Main{
        display:none;
    }
}