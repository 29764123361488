/*Contenedor Principal*/

.us-container-Movil{
    display: none;
  }

  .body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  
  .body-container-MainHome {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-bottom: 3rem;
      height: 90vh;
      /*background: linear-gradient(45deg, #1E5147 30%, #163143  100%);*/
      /*background-image: url('/src//static/images/home/fondomain.jpg'); /* Ruta de la imagen de fondo */
      /*background-size: cover; /* Para que la imagen de fondo cubra todo el contenedor */
      /*background-position: center; /* Para centrar la imagen de fondo */
      /*background-repeat: no-repeat; /* Para evitar la repetición de la imagen */
    }

    

    .body-container-BackgroundImg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: #ebe9e9;
      background-image: url('/src/static/images/home/fondomainA.jpg');  /*Ruta de la imagen de fondo */
      background-position: center; /* Ajusta la posición de la imagen al centro */
      background-repeat: no-repeat;
      /*background-attachment: fixed;  Fija la imagen en su posición */
      background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
      z-index: 0;
    }

    .body-container-BackgroundImgTwo {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background-color: #ebe9e9;
      background-image: url('/src/static/images/home/fondomainB.jpg');  /*Ruta de la imagen de fondo */
      background-position: center; /* Ajusta la posición de la imagen al centro */
      background-repeat: no-repeat;
      /*background-attachment: fixed;  Fija la imagen en su posición */
      background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
      z-index: 0;
    }





    /*Contenedor Secundario*/
    .main-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 0;
      margin-top: 50px;
      margin-bottom: 20px;
    }





    
    
    /*--------Imagen Principal-----------*/

    /*Contenedor de la harina*/
    .container-harina-main{
      display: flex;
      justify-content: center;
      position: absolute;
      height: 900px;
      width: 500px;
      left: 55%;
      z-index: 30;
    }

    /*Empaque de Harina Kaly*/
    .empaque__Harina__Amarilla-New{
      display: flex;
      position: relative;
      z-index: 10;
      top: 24%;
      left: 0%;
      z-index: 30;
      transition: all 0.3s ease-in-out;
    }

    .empaque__Harina__Amarilla-New:hover{
      scale: 1.04;
      cursor: pointer;
    }


    /*Contenedor de Granos*/
    .granos__Maiz{
      display: flex;
      position: absolute;
      height: 900px;
      width: 500px;
    }

    .grano__maizUno{
        position: relative;
        top: 15%;
        left: 19%;
        z-index: 20;
    }

    .grano__maizDos{
      position: relative;
      top: 84%;
      right: 5%;
      z-index: 30;
    }

    .grano__maizTres{
      position: relative;
      top: 42%;
      left: 69%;
      z-index: 30;
    }

    .grano__maizCuatro{
      position: relative;
      top: 10%;
      left: 13%;
      z-index: 20;
    }

    .grano__maizCinco{
      position: relative;
      top: 50%;
      right: 50%;
      z-index: 30;
    }

    .grano__maizSeis{
      position: relative;
      top: 28%;
      right: 53%;
      z-index: 30;
    }

    .grano__maizSiete{
      position: relative;
      top: 20%;
      right: 3%;
      z-index: 10 ;
    }









     /*Contenedor de la harina Verde*/
     .container-harina-main-V{
      display: flex;
      justify-content: center;
      position: absolute;
      height: 900px;
      width: 100%;
      right: 20%;
    }

        /*Empaque de Harina Kaly*/
        .empaque__Harina__Verde-New{
          display: flex;
          position: relative;
          justify-content: center;
          z-index: 10;
          width: 500px;
          top: 24%;
          left: 0%;
          transition: all 0.3s ease-in-out;
        }

        .empaque__Harina__Verde-New:hover{
          scale: 1.04;
          cursor: pointer;
        }























    .us-img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  
    .us-img:hover {
      width: 100%;
      height: 100%;
      transform: scale(1.05);
    }
  










    .main__title__container-v {
      display: flex;
      flex-direction: column;
      position: absolute; 
      justify-content: center;
      width: 900px;
      left: 50%;
    }

    .main__titl-v{
      display: flex;
      flex-direction: column;
      z-index: 30;
    }



  
    /*--------Contenido Información-----------*/
  
    .main__title__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute; 
    }

    .main__title{
      display: flex;
      flex-direction: column;
      position: relative;
      right: 37%;
      z-index: 30;
    }
    
 
    .title-basic-main-prin{
      text-align: start;
      align-items: center;
      color: #ffffff;
      font-family: 'Protest Strike', sans-serif ;
      font-style: normal;
      font-size: 70px;
      line-height: 75px;
      letter-spacing: 0.3px;
      text-decoration-color: #e7eb22;
      width: 700px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.11); /* Agregar sombra: desplazamiento-x desplazamiento-y desenfoque color */
    }

    .title-basic-main-prin-V{
      text-align: start;
      align-items: center;
      color: #ffffff;
      font-family: 'Protest Strike', sans-serif ;
      font-style: normal;
      font-size: 70px;
      line-height: 75px;
      letter-spacing: 0.3px;
      text-decoration-color: #e7eb22;
      width: 700px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.11); /* Agregar sombra: desplazamiento-x desplazamiento-y desenfoque color */
    }

  
    
    .us-text{
      display: flex;
      width: 100%;
      margin-top: 2rem;

    }


    .us-text-v{
      display: flex;
      width: 100%;
      margin-top: 2rem;

    }
  
    .text-basic{
      color: #ffffff;
      text-align: start;
      font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important;
      margin-top: 1.3rem;
      font-weight: 300;
      font-size: 15px;
      letter-spacing: 2;
      line-height: 23px
    }
  
  
    .us-text a{
      text-decoration: none;
    }
  
    .btn-outline{
      display: flex;
      z-index: 100;
      background-color: transparent;
      padding: 0.7rem 3rem;
      border: 1px solid #47A01A;
      transition: all 0.3s ease-out;
      border-radius: 2px;
      text-decoration-line: none;
      outline: none;
      cursor: pointer;
      margin-top: 1.5rem; 
  }
  
  .btn-outline:hover{
    color: rgb(255, 255, 255);
    background-color: #47A01A;
    border: 1px solid #47A01A;
  }
  
  .btn-text{
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 20px;
    color: #47A01A;
  }



  .btn-outline-Main{
    display: inline-block;
    padding: 8px 15px;
    border-radius: 2px;
    text-decoration-line: none;
    outline: none;
    border: 2px solid  #FFD786;
    background-color: #FFD786;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    font-family: "Titillium Web", sans-serif;
    font-size: 0.94rem;
    font-weight: 700;
    color: #503713;
    transition: all 0.1s ease-out;
      filter: brightness(1.2);
  }
  
  .btn-outline-Main:hover{
    display: inline-block;
    border: 2px solid  #ffd900;
    background-color: #ffd900;
    color: #503713;
    transition: all 0.1s ease-out;
    filter: brightness(1.2);
    box-shadow: 0 0 20px #FFD786; 
  }
  
  
  
  
  
  .btn-Incripción:hover{
    display: inline-block;
    border: 2px solid  rgb(193, 193, 4);
    background-color: rgb(193, 193, 4);
    color: #1f1f1f;
    transition: all 0.3s ease-out;
    filter: brightness(1.2);
    box-shadow: 0 0 15px rgba(255, 255, 0, 0.5); 
  }
  
  
  
  
  
    @media screen and (max-width: 960px) {


      .body-container-BackgroundImg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #ebe9e9;
        background-image: url('/src/static/images/home/fondomain-movil.jpg');  /*Ruta de la imagen de fondo */
        background-position: center; /* Ajusta la posición de la imagen al centro */
        background-repeat: no-repeat;
        background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
        z-index: 0;
      }
  

      .main-container{
        display: none;
      }
  
      .body-container-MainHome {
        width: 100%;
        padding: 0rem;
      }

      .body-container {
        width: 100%;
        padding: 0rem;
      }
    
      .us-container {
        display: none;
      }
  
      .us-container-Movil {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        margin-top: 0px;
      }
  
  
      .us-wrap-Movil{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        transition: all 0.3s linear;
        margin-top: 0rem;
    }
  
    .us-img {
        display: flex;
        justify-content: center;
        width: 85%;
        object-fit: cover;
        transition: all 0.2s linear;
        padding: 0rem;
      }
      .us-img:hover {
        display: flex;
        justify-content: center;
        width: 85%;
        object-fit: cover;
        transition: all 0.2s linear;
        transform: scale(1)
      }
  
      .us-wrap-Info-Movil {
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        padding-top: 25.5%;
        margin-bottom: 0rem;
      }
  
      .title-basic-main{
        text-align: center !important;
        align-items: center;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 45px;
        line-height: 48px;
        letter-spacing: 0px;
        text-decoration-color: #e7eb22;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }

      .title-basic-main-prin{
        text-align: center !important;
        align-items: center;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 39px;
        line-height: 48px;
        letter-spacing: 0.5px;
        text-decoration-color: #e7eb22;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        padding-bottom: 0rem;
        width: 100%;
      }

      .title-basic-main-prin-V{
        text-align: center !important;
        align-items: center;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 37px;
        line-height: 48px;
        letter-spacing: 0.5px;
        text-decoration-color: #e7eb22;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        padding-bottom: 0rem;
        width: 100%;
      }

      .title-basic-second{
        text-align: center !important;
        align-items: center;
        color: #87e2a2;
        font-family: 'Roboto', sans-serif !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.5px;
        text-decoration-color: #e7eb22;
      }
  
      .us-text-Movil{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 0 ;
      }
      
      .text-basic-Movil{
        text-align: center;
        font-size: 15px;
        line-height: 26px;
        font-family: "Poppins Regular";
        color: rgb(255, 255, 255);
        padding-top: 1rem;   
        padding-left: 2rem;
        padding-right: 2rem;
        margin: 0px;
      }

      .btn-container-main{
        display: flex;
        justify-content: center;
      }
        .btn-Container{
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
        }
        .btn-Container a{
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          text-decoration: none;
        }
        .btn-outline{
          display: flex;
          justify-content: center;
          width: 80%;
          background-color: transparent;
          padding: 1.1rem 3rem;
          border: 1.5px solid #47A01A;
          transition: all 0.3s ease-out;
          border-radius: 2px;
          text-decoration-line: none;
          outline: none;
          cursor: pointer;  
          margin: auto;
          margin-top: 1.5rem;
          font-weight: 500;
          
      }
      
      .btn-outline:hover{
        color: rgb(255, 255, 255);
        background-color: #47A01A;
        border: 1px solid #47A01A;
        font-family: "Gotham Medium";
      }
      
      .btn-text{
        justify-content: center;
        font-family: "Gotham";
        text-align: center;
        font-size: 16px;
        letter-spacing: 0.4px;
        line-height: 20px
      }
      

      .us-text{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 0rem;
      }
  








       
    
    /*--------Imagen Principal-----------*/

    /*Contenedor de la harina*/
    .container-harina-main-movil{
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 10;
      top: 36%;
    }

    .container-harina-main-movil-V{
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      z-index: 10;
      top: 38%;
    }


    /*Empaque de Harina Kaly*/
    .empaque__Harina__Amarilla-New-movil{
      display: flex;
      position: relative;
      top: 0%;
      z-index: 30;
      transition: all 0.3s ease-in-out;
    }

    .empaque__Harina__Amarilla-New:hover{
      scale: 1.04;
      cursor: pointer;
    }



    /*Contenedor de Granos*/
    .granos__Maiz{
      display: flex;
      position: absolute;
      height: 900px;
      width: 100%;
    }

    .grano__maizUno{
        position: absolute;
        top: -4%;
        left: 5%;
        z-index: 30;
    }

    .grano__maizDos{
      position: absolute;
      top: 37%;
      left: -5%;
      right: 0%;
      z-index: 30;
    }

    .grano__maizTres{
      position: absolute;
      top: 28%;
      left: 75%;
      z-index: 30;
    }

    .grano__maizCuatro{
      position: absolute;
      top: 8%;
      left: 65%;
      z-index: 30;
    }

    .grano__maizCinco{
      position: absolute;
      top: 20%;
      right: 70%;
      left: 0%;
      z-index: 30;
    }

    .grano__maizSeis{
      position: absolute;
      top: 5%;
      right: 60%;
      z-index: 30;
    }

    .grano__maizSiete{
      position: relative;
      top: 20%;
      right: 3%;
      z-index: 10 ;
    }




      
    
  }