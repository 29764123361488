.container-Slick-two {
    display: block;
    justify-content: center;
    width: 1500px;
}

.title-basic-trayectory-company{
    color: #000000;
    font-family: "Montserrat";
    font-size: 42px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -1px;
  }

.us-wrap-work-company{
    position: relative;
    display: inline-block;
    flex-direction: column;
    width: 28rem;
    margin-right: 0rem;
    overflow: hidden;
}

.text-basic-work-company {
    text-align: end;
    font-family: "Poppins";
    margin-top: 1.3rem;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    width: 80%;
  }


  .text-basic__trayectory-company{
    text-align: start;
    font-family: "Poppins";
    margin-top: 1.3rem;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    width: 80%;
  }







  .container__main__sede{
    display:flex ;
    justify-content: center;
    flex-direction: row;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }


  .title_sede{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
    margin-right: 5rem;
    padding-bottom: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }


  .maps_Container{
    display: flex;
    justify-content: center;
  }


  .maps_center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 800px;
    margin-top: 0rem;
  }


  .container__maps{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    align-content: center;
  }


  .maps_center__comp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 500px;
    margin-top: 0rem;;
  }


  .Contact-text {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
  }




  .text-basic-second-Ubi {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    color: #0c4401;
    text-align: start;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 20px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin: 0px;
    margin-top: 2.5rem;
}

  .icon-Contact{
    display: flex;
    font-size: 35px;
    margin-top: 2.8rem;
    color: #0c4401;
    margin-right: 2rem;
  }

  .icon-Contact-phone{
    display: flex;
    font-size: 35px;
    margin-top: 2.8rem;
    color: #0c4401;
    margin-right: 2rem;
  }




  

@media only screen and (max-width: 960px){

    .text-basic__company {
        text-align: start !important;
        justify-content: center;
        width: 97% !important;
        margin: 0px;
        margin-top: 1.7rem;
        font-family: "Poppins";
    }

    .container__main__sede{
      display:flex ;
      justify-content: center;
      flex-direction: column;
      padding-top: 5rem;
      padding-bottom: 0rem;
    }

    .title_sede{
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding-bottom: 6rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .maps_center{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 300px;
      margin-top: 3rem;
    }


    
  .maps_center__comp{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    margin-top: 0rem;;
  }



}

