/*Loanding ebe9e9 */
.loanding__Container{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: hsl(0, 0%, 100%);
    z-index: 1900;
   }

   .loading{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px
   }

   .logo_miceven{
    display: flex;
    width: 200px;
   }

   @media screen and (max-width: 960px) {

    .loanding__Container{
        display: flex;
        position: relative;
        width: 100%;
        height: 100vh;
        background: #015643;
       }


       .logo_miceven{
        display: flex;
        width: 200px;
        position: relative;
       }

   }