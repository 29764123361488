.comp-container-alcance {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0px;
}

.comp-wrap-container-alcance {
    display: flex;
    justify-content: start;
    position: relative;
    width: 43rem;
}

.comp-wrap-container-alcance--Movil {
    display: none;
}


.comp-wrap-alcance {
    display: flex;
    overflow: hidden;

}

.comp-img-alcance {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    right: 0;
    padding: 3rem;
}

.comp-img-alcance:hover {
    scale: 1.05;
}





.comp-wrap-Info-alcance {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 37rem;
    text-align: center;
    justify-content: end;
    align-content: center;
    padding-right: 7rem;
    margin-left: 5rem;
}


@media only screen and (max-width: 960px) {

    .comp-wrap-container-alcance {
        display: none;
    }

    .comp-container-alcance {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding-top: 0px;
        padding-bottom: 50px;
    }

    .comp-wrap-container-alcance--Movil {
        display: flex;
        justify-content: start;
        position: relative;
        width: 100%;
    }


    .comp-img-alcance {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        right: 0;
    }
    

    .comp-wrap-Info-alcance {
        display: flex;
        width: 100%;
        text-align: center;
        align-content: center;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
        margin: 0px;
        margin-top: 2rem;
    }

}

