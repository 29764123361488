.Alianza-fundation-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
    padding-top: 1rem;
}

.Alianza-container--Movil {
    display: none;
}

.container-logo-alianza{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Espacio entre las imágenes */
    margin-top: 4rem;
}

.img-main-alianza {
    width: 7rem;
    height: auto;
    margin: 1rem;
    transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;  /* Agregamos transiciones suaves */
    filter: grayscale(100%); /* Convertimos la imagen a blanco y negro */
    opacity: 0.5;
}

.img-main-alianza:hover {
    transform: scale(1.15); /* Aumenta el tamaño al 110% en el hover */
    filter: grayscale(0%); /* Eliminamos la escala de grises en el hover */
    cursor: pointer;
    opacity: 1;
}


@media only screen and (max-width: 960px) {


    .Alianza-container--Movil {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding-top: 8rem;
        padding-bottom: 10rem;
    }

    .Alianza-fundation-container {
        display: none;
    }

    .container-logo-alianza {
        flex-wrap: wrap; /* Permite que las imágenes se envuelvan cuando no haya suficiente espacio */
        justify-content: space-evenly; /* Distribuye uniformemente las imágenes */
        gap: 10px;
    }
    .img-main-alianza {
        transition: none;
        filter: none;
        opacity: 1;
        flex: 0 0 calc(30% - 2rem); /* Establece el ancho de cada imagen en 50% menos el espacio del margen */
        width: 4rem;
        
    }

    .title-basic-next-border-Pack-D{
        text-align: center;
        align-items: center;
        color: #efffea;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 1.5px;
        margin-top: 0.5rem;
        padding: 10px;
        background-color: #269105;
        display: inline-block;
    }
    
    
}