
.comp-container-two{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
    padding-top: 11rem;
    padding-bottom: 11rem;
}






.comp-container-dest{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    width: 100%;
    padding-top: 11rem;
    padding-bottom: 11rem;
}





.comp-wrap-Info-two {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 35rem;
    text-align: center;
    justify-content: end;
    align-content: center;
    padding-right: 7rem;
    margin-left: 5rem;
}




/*Imagen*/
.comp-wrap-container-two{
    display: flex;
    justify-content: start;
    position: relative;
    width: 43%;
}
.comp-wrap-container-two--Movil{
    display: none;
}

.comp-wrap-two{
    display: flex;
    overflow: hidden;
}





/*Font*/
.text-basic-start {
    display: flex;
    justify-content: start;
    color: #ffffff;
    text-align: start;
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-size: 18px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 30px;
    margin-top: 1.3rem;
    
}

.text-basic-end {
    display: flex;
    justify-content: end;
    color: #ffffff;
    text-align: end;
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-size: 18px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 30px;
    margin-top: 1.3rem;
    
}






.comp-img-two {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    right: 0;
    
  }



  /*Container para Sección Destacamos*/
.comp-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 12rem;
    padding-bottom: 12rem;
}




.text-basic-second-c {
    display: flex;
    justify-content: center;
    width: 100%;
    color: #0c4401;
    text-align: start;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 22px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin-top: 2.5rem;
    
}



@media only screen and (max-width: 960px) {

    .comp-container-two{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 0rem;
        padding-top: 0rem;
        padding-bottom: 5rem;
    }

    .comp-container-dest{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        width: 100%;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    


    .comp-wrap-container-two{
        display: none;
    }
    .comp-wrap-container-two--Movil{
        display: flex;
        justify-content: start;
        position: relative;
        width: 100%;
        padding-top: 0rem;
    }

    .comp-wrap-Info-two {
        display: flex;
        width: 100%;
        text-align: center;
        align-content: center;
        padding-right: 0.8rem;
        padding-left: 0.8rem;
        margin: 0px;
        margin-top: 3rem;
    }

    .text-basic-start {
        display: flex;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        font-family: "Titillium Web", sans-serif;
        font-weight: 300;
        font-size: 17px;
        font-style: normal;
        letter-spacing: 0.7px;
        line-height: 30px;
        margin-top: 1.3rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }



      /*Container para Sección Destacamos*/
    .comp-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 0px;
        padding-top: 0rem;
        padding-bottom: 9rem;
    }

    .text-basic-second-c {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #0c4401;
        text-align: center;
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 0.7px;
        line-height: 30px;
        margin-top: 1.9rem;
    }

}