
/* Harina Blanca */
.harinaBlanca{
position: absolute;
padding-top: 0rem;
top:10%;
left:60%;
z-index: '20';
}

.harinaBlancaMovil{
    position: relative;
    padding-left: 0%;
    top:-30%;
    left: 0%;
    z-index: '20';
}


/* Harina Amarilla */
.harinaAmarilla{
    position: absolute;
    padding-top: 0rem;
    top:10%;
    left: 10%;
    z-index: 20;
    z-index: '20';
}


.harinaAmarillaMovil{
    position: relative;
    padding-left: 0rem;
    top:0%;
    left: 0%;
    z-index: 10;
}


.text-indic{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: #0a5303;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 19px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}

.icon-indic{
    margin-top: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}



@media screen and (max-width: 960px) {

    .text-indic{
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
        color: #0a5303;
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        font-size: 17px;
        font-style: normal;
        letter-spacing: 0.7px;
        line-height: 32px;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }
}