.Inscripction-container {
    background-color: rgba(0,0,0,0.75);
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
    padding-top: 5rem;
    padding-bottom: 7rem;
  }
  

  .Titulo-Principal-Inscripcion{
    font-family: 'Rubik', sans-serif;
    color: #fff;
    font-size: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 100px;
  }
  


/*Color Principal*/
.main-green{
    color: #47A01A;
  }


/*Logo Casasagri*/
.Login__logo__img img {
    margin-top: 0.2em;
    height: 75%;
    width: 6rem;
}

/*Contenedor Principal*/
.Container__Login {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(45deg, #24262b 50%, #21242c 50%); /* Gradiante diagonal dividido */
  }
  



.Container__Form{
    display: flex;
    flex-direction: column;
    width: 700px;
}

.container__Form-Contact{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 800px;
}



.Title__Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}

.AboutUs__title{
    color: #ffffff;
    font-size: 30px;
    letter-spacing: -1px;
    font-family: "Montserrat";
    font-weight: 600;
  }

.Container__Label-Email{
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 500px;
}

.Container__Label-Email-Contact{
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  width: 100%;
}

.Container__Label{
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 500px;
}

.Container__Label-Contact{
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  width: 100%;
}

.Label__Form{
    display: flex;
    text-align: center;
    font-family: "Titillium Web", sans-serif;
    font-weight: 300;
    font-size: 17px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 30px;
    padding: 0rem;
    margin-top: 1rem;
    margin-left: 5px;
    margin-right: 5px;
    transition: all .25s ease-in-out;
    color: rgb(255, 255, 255);
}

.Label__Form-Contact{
  display: flex;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  font-size: 17px;
  font-style: normal;
  letter-spacing: 0.7px;
  line-height: 30px;
  padding: 0rem;
  margin-top: 1rem;
  margin-left: 5px;
  margin-right: 5px;
  transition: all .25s ease-in-out;
  color: #075300 ;
}


.Input__Form{
    display: flex;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.8px;
    padding: 1.0rem;
    color:  #E2E2E2;
    background-color: #1d1e20cb !important;
    outline: none;
    border: solid 1.9px #E2E2E2; 
    border-radius: 20px;
    margin-top: 0.5rem;
}

.Input__Form:hover{
    border: 1.9px solid #ffd800;
    transition: 0.4s;
}
.Input__Form:focus {
    box-shadow: 0px 0px 10px #ffd800;
    border: 1.9px solid #ffd800;
}


.Input__Form-Error{
    display: flex;
    width: 92%;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 1.0rem;
    color:  #E2E2E2;
    background-color: #1d1e20;
    outline: none;
    border: 2.5px solid #ad0f0f; 
    border-radius: 2px;
    margin-top: 0.5rem;
}




.Input__Form-Contact{
  display: flex;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 500;
  letter-spacing: 0.8px;
  padding: 1.0rem;
  color:  #E2E2E2;
  background-color: #ffffffcb !important;
  outline: none;
  border: solid 1.9px #d1e7cf; 
  border-radius: 20px;
  margin-top: 0.5rem;
}

.Input__Form-Contact:hover{
  border: 1.9px solid #a6dda1;
  transition: 0.4s;
}
.Input__Form-Contact:focus {
  box-shadow: 0px 0px 10px #70cf53;
  border: 1.9px solid #a6dda1;
}


/* Estilos para el ícono de mostrar/ocultar contraseña */
.ShowPasswordIcon {
    position: absolute;
    right: 10px; /* Ajusta la posición del ícono según tus preferencias */
    top: 65%; /* Ajusta la posición vertical del ícono */
    transform: translateY(-70%);
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    background-color: #343638;
    width: 32px;
    height: 28px;
    border-radius: 3px;
  }

  .ShowPasswordIcon:hover {
    background-color: #171718;
  }
  
  /* Estilos para el contenedor del campo de contraseña */
  .PasswordInputContainer {
    position: relative;
  }

  .iconJustify{
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  


.container__button{
    display: flex;
    justify-content: center;
    width: 100%;  
    margin-top: 2rem;
}


.Login__btn__outline{
    display: flex;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
    padding: 1.2rem ;
    border: 2px solid #47A01A;
    transition: all 0.3s ease-out;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
    font-family: "Montserrat";
    font-weight: 500;  
}
.Login__btn__outline:hover{
    color: rgb(255, 255, 255);
    background-color: #47A01A;
    border: 2px solid #47A01A;
}



.MensajeError__Input{
    display: flex;
    width: 97%;
    font-size: 11px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 0.5rem;
    color:  #E2E2E2;
    background-color: #ad0f0f;
    outline: none;
    border: solid 1px #a34343; 
    border-radius: 2px;
    margin-top: 0.2rem;
}


.Mensaje__Error{
    display: flex;
    flex-direction: column;
    position: relative;
}



.Container__Label-Dropbox{
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 500px;
    
}

/* Estilo para el campo de carga de archivos */
.FileUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    width: 500px;
    height: 50px;
  }
  
  .UploadIcon {
    font-size: 48px; /* Ajusta el tamaño del ícono según tus necesidades */
    color: #888; /* Cambia el color del ícono si es necesario */
  }
  
  .UploadText {
    margin-top: 10px;
    color: #888; /* Cambia el color del texto si es necesario */
  }
  
  /* Estilo para el campo de entrada de archivo real (oculto) */
  input[type="file"] {
    display: none; /* Oculta el campo de entrada real */
  }

  .container-btn{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .btn-form{
    display: inline-block;
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 2px;
    text-decoration-line: none;
    outline: none;
    border: 2px solid  #ffd800;
    background-color: #ffd800;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: #242222;
    transition: all 0.3s ease-out;
  }

  .btn-form:hover{
    display: inline-block;
    border: 2px solid  #fbff1d;
    background-color: #fbff1d;
    color: #242222;
    transition: all 0.3s ease-out;
  }

  .btn-form-inActive{
    display: inline-block;
    padding: 15px 25px;
    font-size: 18px;
    border-radius: 2px;
    text-decoration-line: none;
    outline: none;
    border: 2px solid  #747474;
    background-color: #494949;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    color: #747474;
    transition: all 0.3s ease-out;
  }

  @media screen and (max-width: 768px) {

    .Titulo-Principal-Inscripcion{
      width: 70%;
      line-height: 60px;
    }
  
    .Inscripction-container {
      padding-top: 3rem;
      padding-bottom: 5rem;
      width: 100%;
    }

    .Container__Label-Email{
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      width: 100%;
  }

  .Container__Label-Email-Contact{
    display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      width: 100%;
  }

  .Container__Label{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
}
  .Container__Label-Contact{
    display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 1rem;
      width: 100%;
  }
  
  .Container__Label-Dropbox{
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 350px;  
  }

  .Input__Form{
    width: 100%;
  }

  .container__Form-form{
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .container__Form-form-Contact{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .container__Form-Contact{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
}


}
  