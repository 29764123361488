.img-main{
    display: flex;
    justify-content: center;
    width: 23rem;
    height: 100%;
    transition: transform 0.3s ease;
}

.img-main:hover {
    transform: scale(1.05) !important; /* Al pasar el cursor, la imagen se escalará un 10% más grande */
    cursor: pointer;
  }
  

.container-BackgroundImg {
    display: block;
    position: absolute;
    width: 100%;
    height: 55rem;
    justify-content: center;
    align-items: center;
    background-color: #ebe9e9;
    background-image: url('/src/static/images/home/BannerAfs.jpg');  /*Ruta de la imagen de fondo */
    background-position: center; /* Ajusta la posición de la imagen al centro */
    background-repeat: no-repeat;
    background-attachment: fixed; /* Fija la imagen en su posición */
    background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
    z-index: 0;
  }



  

  .body-container-Miceven {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 11rem;
}





  .Logo-fundation-container {
    display: flex;
    flex-direction: column;
    width: 55%;
    text-align: center;
    padding-top: 2rem;
    position: relative; /* Para posicionar el triángulo */
}


.Logo-fundation-container--Movil{
    display: none;
}

.title-basic-next{
    text-align: center;
    align-items: center;
    color: #269105;
    color: #096a01 !important;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 68px;
    letter-spacing: 1.5px;
}

.title-basic-next-border{
    text-align: center;
    align-items: center;
    color: #efffea;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 68px;
    letter-spacing: 1.5px; 
    /*border: 3px solid #4f903c;  Ancho y color del borde */
    padding: 10px; /* Espacio entre el texto y el borde */
    background-color: #f0c612; /* Color de fondo oscuro */
    background-color: #096a01; /* Color de fondo oscuro */
    display: inline-block; /* Para que el fondo se ajuste al tamaño del texto */
}

.container-text-main{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0%;
}

.text-basic-second-p {
    display: flex;
    justify-content: center;
    width: 60%;
    color: #0e6806;
    text-align: center;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 22px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin-top: 2.5rem;
    
}






.container-imagen{
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #096a01;
    width: 100%;
}

.arepa-cont{
    display: flex;
    position: absolute;
    top: 30%;
    left: 0%;
    width: 40%;
}


@media only screen and (max-width: 960px) {


    .body-container-Miceven {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 7rem;
        padding-bottom: 10rem;
    }

    .Logo-fundation-container{
        display: none;
    }
    .Logo-fundation-container--Movil{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 0rem;
    }

    .img-main{
        width: 17rem;
        height: 100%;
        margin-top:2rem;
    }
    
    .title-basic-next{
        text-align: center;
        align-items: center;
        color: #269105;
        color: #096a01 !important;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 1.5px;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }


    .title-basic-next-border{
        text-align: center;
        align-items: center;
        color: #269105;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: 1.5px; 
        margin-top: 0.3rem;
        padding: 10px; /* Espacio entre el texto y el borde */
        background-color: #096a01; /* Color de fondo oscuro */
        display: inline-block; /* Para que el fondo se ajuste al tamaño del texto */
    }



    .text-basic-second-p {
        display: flex;
        justify-content: center;
        width: 90%;
        color: #0d6305;
        text-align: center;
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 0.7px;
        line-height: 30px;
        margin-top: 1.9rem;
    }

    .container-text-main{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    

}