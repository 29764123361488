.containerTop {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    width: 100%;
    flex-direction: column;
    background-color: #004729;
    height: 35px;
    z-index: 100 !important;
    transition: all 0.3s ease-out;
    position: relative; /* Cambia la posición a relative, absolute, fixed o sticky */
  }

  .content-Top {
    display: flex;
    width: 80%;
    justify-content: end;
    z-index: 100;
  }

  .icon-Top{
    font-size: 17px;
    color: rgb(192, 192, 192);
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0rem;
    transition: all 0.3s ease-out;
  }

  .icon-Top:hover{
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .icon-Top-Person{
    font-size: 23px;
    color: rgb(192, 192, 192);
    margin-left: 1rem;
    margin-right: 1rem;
    transition: all 0.3s ease-out;
  }

  
  .icon-Top-Person:hover{
    color: rgb(255, 255, 255);
    cursor: pointer;
  }





  /* Contenedores para la seccion de Ayuda */
  .optionDrop-Top-Help{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 0.5s ;
    width: 100%;
    height: 0px;
    z-index: 60;
    background-color: #004729;
  }

  .optionDrop-Top-Help.active{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 200;
  }


  
  .optionDrop-Top-Content-help{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 0rem;
    transition: 0.5s;
    z-index: 700;
  } 


  .optionDrop-Top-Help-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 0;
  }





  /* Contenedores para la seccion de Usuario */
  .optionDrop-Top{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: 0.5s ;
    width: 100%;
    height: 0px;
    z-index: 60;
    background-color: #004729;
  }

  .optionDrop-Top.active{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 300px;
    z-index: 200;
  }




  .optionDrop-Top-Content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4rem;
    margin-right: 4rem;
    padding-top: 1rem;
    transition: 0.5s;
    text-decoration: none;
  } 

  .optionDrop-Top-Content:hover{
    display: flex;
    padding-top: 0rem;
    cursor: pointer;
  } 








  .icon-closeDrop {
    display: flex;
    position: absolute;
    right: 15%;
    top: 10%;
    font-size: 15px;
    color: #b4b4b4;
    padding: 0rem; 
    border-radius: 4px; 
    transition: 0.15s ;
  }
  .icon-closeDrop:hover {
    display: flex;
    color: #ffffff;
    position: absolute;
    font-size: 16px;
    cursor: pointer;
  }

  .icon-closeDrop.desactive{
    opacity: 0;
  }







  .icon-optionDrop {
    display: flex;
    font-size: 40px;
    color: black; 
    background-color: white; 
    padding: 0.4rem; 
    border-radius: 4px; 
    transition: 0.3s ;
  }

  .icon-optionDrop.desactive{
    opacity: 0;
  }


  .title-icon-optionDrop {
    text-align: center;
    align-items: center;
    color: #ffffff;
    font-family: "Titillium Web", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-decoration-color: #e7eb22;
    margin-top: 1rem;
    transition: 0.3s ;
}


.title-icon-optionDrop.desactive{
    opacity: 0;
}





.title-help{
  text-align: center;
  align-items: center;
  color: #ffffff;
  font-family: "Titillium Web", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-decoration-color: #e7eb22;
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  transition: 0.3s ;
}


.title-help.desactive{
  opacity: 0;
}

  
@media screen and (max-width: 960px) {

    .containerTop {
        display: flex;
        height: 40px;
        
      }

    .content-Top {
        display: flex;
        width: 100%;
        justify-content: end;
        z-index: 50;
      }





      .icon-Top{
        font-size: 16px;
        margin-top: 0.15rem;
        
      }


      .icon-Top-Person{
        font-size: 23px;
      }



      .icon-optionDrop {
        display: flex;
        z-index: 50;
      }


      .icon-closeDrop {
        display: flex;
        position: absolute;
        right: 7%;
        top: 5%;
      }



      .optionDrop-Top{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 0px;
        z-index: 1000;
        background-color: #004729;
        transition: 0.5s ;
      }
    
      .optionDrop-Top.active{
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 300px;
        margin-top: 0rem;
      }



      .optionDrop-Top-Content{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-top: 1rem ;
        padding-bottom: 1rem ;
        transition: 0.3s;
      } 


      .title-icon-optionDrop {
        margin-left: 1.6rem;
        transition: 0.3s ;
    }

    .title-help {
      transition: 0.3s ;
      padding-bottom: 2rem;
  }





  /* Contenedores para la seccion de Ayuda */
  .optionDrop-Top-Help{
    display: flex;
    flex-direction: column;
  }

  .optionDrop-Top-Help.active{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 300px;
  }


  
  .optionDrop-Top-Content-help{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 0rem;
    transition: 0.5s;
    z-index: 700;
  } 


  .optionDrop-Top-Help-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

    
    


    
}