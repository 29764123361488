


.Logo-fundation-container-Nosotros{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-top: 5rem;
}


.Logo-main-container{
    display: flex;
    justify-content: center;
}

.img-main-Nosotros{
    display: flex;
    justify-content: center;
    margin-top: 0rem;
    width: 20rem;
    transition: transform 0.3s ease;
}

.img-main-Nosotros:hover {
    transform: scale(1.1) !important; /* Al pasar el cursor, la imagen se escalará un 10% más grande */
    cursor: pointer;
  }
  


.container-nosotros-title{
    display: flex;
    width: 880px;
    justify-content: center;
    flex-direction: column;
    padding-left: 5rem;
    padding-right: 3rem;
}


.title-basic-nosotros{
    display: flex;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    color: #0c4401;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 68px;
    letter-spacing: 1.5px;
    text-decoration-color: #e7eb22;
}

.container-text-nosotros{
    display: flex;
    justify-content: center;
}

.text-basic-second {
    display: flex;
    justify-content: center;
    width: 600px;
    color: #0c4401;
    text-align: center;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    font-size: 22px;
    font-style: normal;
    letter-spacing: 0.7px;
    line-height: 32px;
    margin-top: 2.5rem;
    
}


@media only screen and (max-width: 960px) {

   
    .Logo-fundation-container-Nosotros{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        margin-top: 5rem;
    }


    .Logo-main-container{
        display: flex;
        justify-content: center;
    }

    .img-main-Nosotros{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 0.5rem;
        width: 230px;
        height: 100%;
    }


    .container-nosotros-title{
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        margin-top: 3rem;
        padding-bottom: 7rem;
    }


    .title-basic-nosotros{
        display: none;
    }


    .container-text-nosotros{
        display: flex;
        justify-content: center;
        padding-left: 0rem;
        padding-right: 0rem;
    }


    .text-basic-second {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #0c4401;
        text-align: center;
        font-family: "Titillium Web", sans-serif;
        font-weight: 500;
        font-size: 20px;
        font-style: normal;
        letter-spacing: 0.7px;
        line-height: 30px;
        margin-top: 1.9rem;
        padding: 0.5rem;
        
    }
    
}