/* Contaniner Navbat */
.navbar-init {
  background: #0e2f1d00;
  height: 90px;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 50;
  letter-spacing: 0px;
  margin: auto;
  width: 100%;
  transition: all 0.3s ease-out;
  z-index: 60;
}

.navbar-scroll {
  /* background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255)  100%);*/ 
  background: #015643;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 50;
  letter-spacing: 0px;
  margin: auto;
  width: 100%;
  transition: all 0.3s ease-out;
  z-index: 60;
}



.navbar-logo {
  display: flex;
  justify-self: start;
  justify-content: center;
  position: absolute;
  left: 15%;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  padding-top: 0rem;
  width: 90px;
  height: 90px;
  transition: all 0.1s linear;
  z-index: 50 !important;
}

.navbar-logo-scrolling{
  /*filter: brightness(0) invert(100%) ;*/
  display: flex;
  justify-self: start;
  justify-content: center;
  position: absolute;
  left: 15%;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  padding-top: 0rem;
  width: 90px;
  height: 90px;
  transition: all 0.1s linear;
  z-index: 50 !important;
}


.navbar-logo:hover {
  scale: 1.08;
}

.navbar-logo-scrolling{
  scale: 1.08;
}






.logo-Main{
  filter: invert(0.5);
  opacity: 1;
  transition: all .4s ease-in-out;
}
.logo-Main:hover{
  filter: invert(0);
  opacity: 1;
  transition: all .4s ease-in-out;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  padding-top: 1rem;
  margin-right: 2rem;
 
}



.nav-item {
  display: flex;
  align-items: center;
  height: 80px;

}


/*Texto de Opciones*/

.nav-links {
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-out;
  margin-bottom: 0;
  position: relative;
  z-index: 999 !important;
 
}
.nav-links:hover {
  padding: auto;
  color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-out;
}

.nav-links::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff005;
  transition: width 0.5s ease, left 0.5s ease; 
}

.nav-links:hover::after {
  width: 80%;
}

.nav-links:not(:hover)::after {
  width: 0; 
}




.nav-links-scroll {
  display: flex;
  position: relative;
  color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none !important;
  text-align: center;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-out;
  margin-bottom: 0;
  position: relative;

}

.nav-links-scroll:hover {
  padding: auto;
  color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-out;
}

.nav-links-scroll::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #fff005;
  transition: width 0.5s ease, left 0.5s ease; 
}

.nav-links-scroll:hover::after {
  width: 80%;
}

.nav-links-scroll:not(:hover)::after {
  width: 0; 
}






.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}





.btn-Incripción{
  display: inline-block;
  padding: 5px 12px;
  border-radius: 2px;
  text-decoration-line: none;
  text-decoration: none;
  outline: none;
  border: 2px solid  rgb(6, 97, 6);;
  background-color: rgb(6, 97, 6);;
  border-radius: 50px;
  cursor: pointer;
  margin: 10px;
  font-family: "Titillium Web", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  transition: all 0.3s ease-out;
    filter: brightness(1.2);

}



  



.btn-Incripción:hover{
  display: inline-block;
  border: 2px solid  rgb(193, 193, 4);
  background-color: rgb(193, 193, 4);
  color: #1f1f1f;
  transition: all 0.3s ease-out;
  filter: brightness(1.2);
  box-shadow: 0 0 15px rgba(255, 255, 0, 0.5); 
}













.menu-icon-mov {
  display: block;
  margin-left: 62.5%;
  width: 25px;
  height: 20px;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index: 50;
}

.menu-icon-mov.open{
  display: flex;
  margin-left: 0%;
  transition: transform 0.3s ease;
}


.menu-icon-mov div {
  width: 100%;
  height: 2px;
  margin-left: 0%;
  background-color: #ffffff;
  position: absolute;
  transition: transform 0.3s ease;
}

.menu-icon-mov div:nth-child(1) {
  top: 0;
}

.menu-icon-mov div:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.menu-icon-mov div:nth-child(3) {
  bottom: 0;
}

.menu-icon-mov.open div:nth-child(1) {
  margin-left: 0%;
  transform: translateY(520%) rotate(45deg);
}

.menu-icon-mov.open div:nth-child(2) {
  margin-left: 0%;
  opacity: 0;
}

.menu-icon-mov.open div:nth-child(3) {
  margin-left: 0%;
  transform: translateY(-380%) rotate(-45deg);
}





.nav-menu-Movil {
  display: none;
}

.menu-icon-mov-Movil {
  display: none;
}

.menu-icon-mov-Movil.open{
  display: none;
}



















@media screen and (max-width: 960px) {

 /* 0e2f1d*/


  .navbar-init {
    background: #0e2f1d00;
    position: fixed;
    justify-content: center;
    transition: all 0.4s ease-out;
  }

  .navbar-scroll {
    background: #015643;
    position: fixed;
    justify-content: center;
    
  }

  .navbar-click{
    height: 90px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 100;
    letter-spacing: 0px;
    margin: auto;
    width: 100%;
    transition: all 0.4s ease-out;
    background: #04301d;
    position: fixed;
    justify-content: center;
  }

  .navbar-click-scroling{
    height: 90px;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 100;
    letter-spacing: 0px;
    margin: auto;
    width: 100%;
    transition: all 0.4s ease-out;
    background: #04301d;
    position: fixed;
    justify-content: center;
    top: 0;
  }
  

  .menu-icon-mov {
    display: none;
  }
  
  .menu-icon-mov.open{
    display: none;
  }
  




  
  .navbar-logo {
    display: block;
    position: absolute;
    margin-top: 0rem;
    left: 5%;
    width: 80px;
    height: 73.253px;
  }

  .navbar-logo-scrolling {
    display: block;
    position: absolute;
    padding-top: 0rem;
    left: 5%;
    width: 80px;
    height: 73.253px;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu-Movil {
    display: block;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 95vh;
    top: 90px;
    left: 900px;
    opacity: 1;
    transition: 1.5s ;
    z-index: 200;
    padding: 0px;
    padding-top: 5rem;
    background-color: #04301d;
  }

  .nav-menu-Movil.active {
    display: block;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 95vh;
    top: 90px;
    left: 0;
    opacity: 1;
    transition: 0.5s ;
    z-index: 200;
    padding: 0px;
    padding-top: 5rem;
    background-color: #04301d;
  }


  .nav-links-movil {
    text-align: center;
    font-family: "Titillium Web", sans-serif !important;
    color: #ffffff;
    padding: 0rem;
    width: 100%;
    border: 3rem;

  }

  .fa-times {
    color: #41AB34;
    font-size: 2rem;
  }


  .fa-bars {
    color: #ffffff;
  }

  .green {
    color: #41AB34 !important;
  }

  




  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    font-family: "Titillium Web", sans-serif !important;
    width: 100%;
    background: #278d0a;
    text-decoration: none;
    color: #ffffff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #278d0a;
    transition: 250ms;
  }

  button {
    display: none;
  }
  .nav-item{
    display: flex;
    justify-content: center;
  }

  .nav-item-null{
    display:none;
  }

  .btn-Incripción{
    display: flex;
    justify-content: center;
    padding: 9px 20px;
    font-size: 19px;
    border-radius: 2px;
    text-decoration-line: none;
    outline: none;
    border: 2px solid  yellow;
    background-color: yellow;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    font-family: "Titillium Web", sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0px;
    color: #04160c;
    transition: all 0.3s ease-out;
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5); 

  }







  .menu-icon-mov-Movil {
    display: block;
    margin-left: 80%;
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    z-index: 200;
    transition: transform 0.3s ease;
  }
  
  .menu-icon-mov-Movil.open{
    display: flex;
    margin-left: 80%;
    transition: transform 0.3s ease;
  }
  
  
  .menu-icon-mov-Movil div {
    width: 100%;
    height: 2px;
    margin-left: 0%;
    background-color: #ffffff;
    position: absolute;
    transition: transform 0.3s ease;
  }
  
  .menu-icon-mov-Movil div:nth-child(1) {
    top: 0;
  }
  
  .menu-icon-mov-Movil div:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .menu-icon-mov-Movil div:nth-child(3) {
    bottom: 0;
  }
  
  .menu-icon-mov-Movil.open div:nth-child(1) {
    margin-left: 0%;
    transform: translateY(500%) rotate(45deg);
  }
  
  .menu-icon-mov-Movil.open div:nth-child(2) {
    margin-left: 0%;
    opacity: 0;
  }
  
  .menu-icon-mov-Movil.open div:nth-child(3) {
    margin-left: 0%;
    transform: translateY(-380%) rotate(-45deg);
  }
  


}
