 /*Banner de Publicidad*/
 .Carrousel__Banner__Movil{
  display:none;
}
.Carrousel__Banner__Desktop{
  display:block;
  margin-bottom: 2rem;
  margin-top: 0px;
}
 
 
 /*-----------Banner-----------*/

  .Banner-Category-Movil{
    display: none;
  }

  .Banner-Category-Desktop{
    display: block;
    /*height: 480px;*/
  }

  .container-Category-Main{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100% !important;
  }

  .banner-img{
    display: flex;
    object-fit: cover;
    width:100%;
    height: 50vh;
    z-index: 5;
  }
  .banner-img:hover{
    cursor: pointer;
  }

  .container-Category-Main-Content{
    display: flex;
    position: absolute;
    justify-content: flex-start;
    margin-left: 15%;
    margin-top: 6%;
  }

  .container-Banner-Category-Content-title {
    display: flex;
    justify-content: center;
    width: 500px;
    z-index: 10;
    line-height: 50px;
    color: rgb(34, 34, 34);
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 3rem;
    line-height: 2.5rem;
    letter-spacing: -1px;
  }

  .img__Banner{
    display: block;
    height: 100%;
  }


  @media screen and (max-width: 960px) {

    /*Banner de Publicidad*/
    .Carrousel__Banner__Movil{
      display:block;
      padding-top: 0px;
    }
    .Carrousel__Banner__Desktop{
      display:none;
    }



    .Banner-Category-Desktop{
      display: none;
    }
    .container-Category-Main{
      display: none;
    }

    .Banner-Category-Movil{
      display: inline-block;
      padding-top: 0px;
      width: 100%;
      height: 100%;
    }

    .container-Category-Main-Movil{
      display: inline-flex;
      width: 100%;
      height: 13rem;
    }

    .banner-img-Movil{
      display: flex;
      object-fit: cover;
      width:100%;
      height: 30vh;
    }


    .container-Banner-Category-Content-Movil{
      display: none;
    }
    .container-Banner-Category-Content-title-Movil {
      display: flex;
      justify-content: center;
      width: 90%;
      margin-top: 0rem;
      margin-bottom: 0.6rem;
      padding-left: 10px;
      padding-right: 10px;
      z-index: 10;
      font-size: 3rem;
      line-height: 50px;
      color: rgb(34, 34, 34);
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 2.5rem;
      letter-spacing: -1px;
    }


  }