/*Color Principal*/
.main-green{
    color: #47A01A;
  }


  .title__home__White-Login {
    text-align: center !important;
    align-items: center;
    color: #ffffff;
    font-family: 'Protest Strike', sans-serif !important;
    font-weight: 500;
    font-size: 40px;
    line-height: 68px;
    letter-spacing: 0.7px;
}



.Label__Form-Login{
  display: flex;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-size: 15px;
  font-style: normal;
  letter-spacing: 0.7px;
  line-height: 30px;
  padding: 0rem;
  margin-top: 0.3rem;
  margin-left: 5px;
  margin-right: 5px;
  transition: all .25s ease-in-out;
  color: rgb(255, 255, 255);
}


  .body-container-BackgroundImg-Login {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }

  .img-Login{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 90vh;
    z-index: -1;
  }

  .img-Login-Movil{
    display: none
  }



/*Logo Casasagri*/
.Login__logo__img{
    display: flex;
    justify-content: center;
    width: 38%;
}
.Login__logo__img img {
    display: flex;
    margin-top: 0.2em;
    width: 18rem;
    margin-left: 5rem;
    margin-right: 5rem;
}

/*Contenedor Principal*/
.Container__Login-main {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 10;
    height: 90vh;

    /*  background-image: linear-gradient(45deg, #24262b 50%, #21242c 50%);Gradiante diagonal dividido */
  }
  



.Container__Form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 450px;
    background-color: #ffffff1e;
    padding: 2rem;
    border-radius: 20px;
    backdrop-filter: blur(5px);
}

.Container__Form-Register{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 450px;
  background-color: #ffffff1e;
  padding: 2rem;
  margin-top: 3rem;
  border-radius: 20px;
  backdrop-filter: blur(5px);
}




.Title__Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0rem;
    padding-bottom: 0.5rem;
}

.AboutUs__title{
    color: #ffffff;
    font-size: 30px;
    letter-spacing: -1px;
    font-family: "Montserrat";
    font-weight: 600;
  }

.Container__Label-Login{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    width: 100%;
}

.Container__Label-Register{
  display: flex;
  flex-direction: column;
  padding-top: 0rem;
  width: 100%;
}

.Container__Label{
    display: flex;
    flex-direction: column;
    padding-top: 3.5rem;
    
}

.Label__Form-basic{
    display: flex;
    flex-direction: row;
    text-align: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
    /*text-transform: uppercase;*/
    padding: 0rem;
    margin-left: 5px;
    margin-right: 5px;
    transition: all .25s ease-in-out;
    color: rgb(255, 255, 255);
    margin-top: 2rem;
}

.Label__Form-Link{
  display: flex;
  text-align: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 0rem;
  margin-left: 15px;
  transition: all .25s ease-in-out;
  color: #fff006;
}


.Label__Form-Link:hover{
  display: flex;
  text-align: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
  padding: 0rem;
  margin-left: 15px;
  transition: all .25s ease-in-out;
  color: #f7ff88;
}


.Input__Form-Login{
    display: flex;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.8px;
    padding: 1.0rem;
    color:  #E2E2E2;
    background-color: #2249258c !important;
    outline: none;
    border: solid 1.9px #E2E2E2; 
    border-radius: 20px;
    margin-top: 0.5rem;
    width: 100%;
}

.Input__Form-Login:hover{
    border: 1.9px solid #ffd800;
    transition: 0.4s;
}
.Input__Form-Login:focus {
    box-shadow: 0px 0px 10px #ffd800;
    border: 1.9px solid #ffd800;
}


.Input__Form-Error{
    display: flex;
    width: 92%;
    font-size: 13px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 1.0rem;
    color:  #E2E2E2;
    /*background-color: #1d1e20;*/
    outline: none;
    border: 2.5px solid #ad0f0f; 
    border-radius: 2px;
    margin-top: 0.5rem;
}



/* Estilos para el ícono de mostrar/ocultar contraseña */
.ShowPasswordIcon {
    position: absolute;
    right: 10px; /* Ajusta la posición del ícono según tus preferencias */
    top: 65%; /* Ajusta la posición vertical del ícono */
    transform: translateY(-70%);
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    background-color: #0b4b1a;
    width: 32px;
    height: 28px;
    border-radius: 3px;
  }

  .ShowPasswordIcon:hover {
    background-color: #093a14;
  }
  
  /* Estilos para el contenedor del campo de contraseña */
  .PasswordInputContainer {
    position: relative;
  }

  .iconJustify{
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  


.container__button{
    display: flex;
    justify-content: center;
    width: 100%;  
    margin-top: 2rem;
}


.Login__btn__outline{
    display: flex;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
    padding: 1.2rem ;
    border: 2px solid #47A01A;
    transition: all 0.3s ease-out;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
    font-family: "Montserrat";
    font-weight: 500;  
}
.Login__btn__outline:hover{
    color: rgb(255, 255, 255);
    background-color: #47A01A;
    border: 2px solid #47A01A;
}



.MensajeError__Input{
    display: flex;
    width: 97%;
    font-size: 11px;
    font-family: 'Montserrat';
    font-weight: 500;
    letter-spacing: 0.1px;
    padding: 0.5rem;
    color:  #E2E2E2;
    background-color: #ad0f0f;
    outline: none;
    border: solid 1px #a34343; 
    border-radius: 2px;
    margin-top: 0.2rem;
}


.Mensaje__Error{
    display: flex;
    flex-direction: column;
    position: relative;
}


.container__button-Login {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}



@media screen and (max-width: 960px) {

  .img-Login{
    display: none
    
  }

  .img-Login-Movil{
    display: flex;
    justify-content: center;
    position: absolute;
    width: 110%;
    height: 90vh;
    z-index: -1;
  }

    .Container__Login-main {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        height: 90vh;
      }
      

      .Login__logo__img{
        display: none
    }


      .Container__Form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90%;
        background-color: #ffffff1e;
        padding: 2rem;
        border-radius: 20px;
        margin-top: 20%;
    }

    .Container__Form-Register{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 90%;
      background-color: #ffffff1e;
      padding: 1rem;
      border-radius: 20px;
      margin-top: 20%;
  }



    .Container__Label-Login{
      display: flex;
      flex-direction: column;
      padding-top: 0.8rem;
      width: 100%;
  }

  .Container__Label-Register{
    display: flex;
      flex-direction: column;
      padding-top: 0rem;
      width: 100%;
}

  .Label__Form-Link{
    display: flex;
    text-align: center;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    margin-left: 0px;
    margin-top: .3rem;
  }

  .Label__Form-basic{
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-top: 2rem;
  }

  .container__button-Login {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
  }
}