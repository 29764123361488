
  /*----------------------- Elementos diagramados en la pagina ------------------------------------*/




  .mainContainer{
    display: inline-block;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  .CarruselDiscover__Desktop .slick-track{
    /*height: 30rem;*/
  }

  .container-desktop{
    width: 100%;
    margin: 0;
  }

  .container-img{
    display: inline-block;
    object-fit: cover;
    width:100%;
  }
  .mainContainer-Movil{
    display: none;
  }


  .img__Banner-Main{
    display: block;
    height: 25vh;
  }

  .img__Banner-Main:hover{
    transform: scale(1.1);
  }



/*---------------------------Elementos del carousel-------------------
  .slick-list{
    position: relative;
    
  }

  .slick-list:hover{
    cursor: none !important;
  }

  .slick-track{

  }

  .slick-slide{

    
  }

  .slick-active{

  }

  .slick-cloned{

  }


  .slick-next{
    margin-right: 10%;
    z-index: 5;
    opacity: 0.7;
  }
  .slick-next:hover{
    opacity: 1;
  }


  .slick-prev{
    margin-left: 10%;
    z-index: 10;
    opacity: 0.7;
  }
  .slick-prev:hover{
    opacity: 1;
}
  
*/

  @media screen and (max-width: 960px) {

    /*----Contenedor Principal----*/
    .mainContainer{
     display: none;
    }
    
    .mainContainer-Movil{
      display: inline-block;
      width: 100%;
    }
    .container-Movil{
      display: inline-flex;
      width: 100%;
      padding-top: 80px;
    }
    .container-img-Movil{
      display: flex;
      object-fit: cover;
      width:100%;
      height: 90vh;
    }



    /*----carrousel----*/
    .mainContainer-Movil .slick-list{
      position: relative;
      z-index: 8;
      height: 90vh;
    }

   

    /*----Boton next----*/
    .slick-next{
      margin-right: 10%;
      z-index: 15;
      opacity: 0.7;
    }

    /*-----Boton next------*/
    .slick-prev{
      margin-left: 10%;
      z-index: 10;
      opacity: 0.7;
    }





    .Carrousel__Banner__Movil {
      width: 100%;
      height: 100% ;
      overflow: hidden;
    }

    .desktop-video::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4); /* Color oscuro semi-transparente */
    }






    .video_title-container-Movil {
      position: absolute;
      display: flex;
      justify-content: start;
      flex-direction: column;
      align-items: center;
      width: 100%;
      top: 35.1%;
      z-index: 10;
      padding-top: 2rem;
      padding-bottom: 2.5rem;
      overflow: hidden; /* Para ocultar el exceso del triángulo */
      background-color: rgb(2, 58, 24);
    }
    
    .video_title-container-Movil::before {
      display: flex;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 250px 100vw; /* Ajusta el tamaño del triángulo según lo necesites */
      border-color: transparent transparent #1b6d1017 transparent; /* Ajusta el color del triángulo */
    }


    .video_title-container-Movil-img {
      position: absolute;
      display: flex;
      justify-content: start;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 25%;
      top: 35.1%;
      z-index: 10;
      padding-top: 5rem;
      overflow: hidden; /* Para ocultar el exceso del triángulo */
     /* background-image: url('/public/images/Company/nosotros-movil.png'); /*Reemplaza 'ruta/de/tu/imagen.jpg' con la ruta de tu imagen */
      background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
      background-position: center; /* Centra la imagen en el contenedor */
      margin-bottom: 2rem;
    }




    .video_title-main{
      text-align: center !important;
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-size: 60px;
      font-weight: 800;
      line-height: 60px;
      letter-spacing: 0.5px;
      text-decoration-color: #f6e923;
      margin: 0px;
      z-index: 50;
    }


    .video_title-Subtitle{
      text-align: center !important;
      color: #ffffff;
      font-family: 'Protest Strike', sans-serif !important;
      font-size: 23px;
      line-height: 23px;
      letter-spacing: 1.5px;
      text-decoration-color: #e7eb22;
      margin: 0px;
      padding-bottom: 0.7rem;
      z-index: 1;

    }
  


    .movil-video video {
      height: 90vh !important;
      object-fit: cover; /* Ajusta el contenido del video para cubrir completamente el contenedor */
    }






    
  }



  @media (min-width: 768px) {

  .Carrousel__Banner__Desktop {
      width: 100%;
      height: 85vh;
      overflow: hidden;
    }

    .desktop-video {
      top: 0;
      left: 0;
      width: 100%;
      height: 85vh;
      z-index: -1; /* Para asegurar que el video esté detrás de otros contenidos */
    }

    .desktop-video::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4); /* Color oscuro semi-transparente */
    }

    .desktop-video video {
      width: 100%;
      object-fit: cover; /* Ajusta el contenido del video para cubrir completamente el contenedor */
    }






    .video_title-container{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      
    }


    .video_title-main{
      text-align: center !important;
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-size: 100px;
      font-weight: 800;
      line-height: 100px;
      letter-spacing: 3px;
      text-decoration-color: #f6e923;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      padding-bottom: 0rem;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.20); /* Agrega sombra */
    }


    .video_title-Subtitle{
      text-align: center !important;
      color: #ffffff;
      font-family: 'Protest Strike', sans-serif !important;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.8px;
      text-decoration-color: #e7eb22;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      z-index: 2;
    }
  }