 /*Contenedor Secundario*/
 .ub-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  margin-top: 50px;
  margin-bottom: 20px;
}

/*--------Imagen Principal-----------*/
.ub-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50rem;
  right: 0%;
  left: -1%;
  margin-left: 0rem;
  overflow: hidden;
  padding-bottom: 0rem;
}

.ub-img {
  display: flex;
  position: relative;
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}





.ub-wrap-Info {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 40rem;
  margin-top: 0rem;
  text-align: center;
  justify-content: center;
  align-content: center;
}


.ub-container-Movil {
  display: none
}


@media screen and (max-width: 960px) {

  .ub-container {
    display: none
  }

  .ub-container-Movil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 5rem;
  }
  
  

  .ub-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    right: 0%;
    left: 0%;
    margin-left: 0rem;
    padding-bottom: 2rem;
    overflow: hidden;
  }

  .ub-wrap-Info-Movil {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    margin-top: 0rem;
    text-align: center;
    justify-content: center;
    align-content: center;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}