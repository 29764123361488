.carusel-center-main-Home {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 0rem;
    height: 100%;
}

.rounded-carousel .carousel-inner {
    border-radius: 20px; /* Ajusta este valor según lo desees */
    overflow: hidden;
    width: 100rem;
    height: 40rem;
    display: flex;
}

.contaniner-info-Product{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
    padding-bottom: 5rem;
}

.contaniner-info-Product-Movil{
    display: none;
}

.container-Harina-main{
    display: flex;
    justify-content: center;
    flex-direction: row;
}




.Container-3d{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.Container-3d-Movil{
    display: none
}

.icon-3d{
    display: flex;
    justify-content: center;
    position: relative;
    top: 90;
    left: 80;
}



.body-container-BackgroundImg-Banner {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-position: center; /* Ajusta la posición de la imagen al centro */
    background-repeat: no-repeat;
    /*background-attachment: fixed;  Fija la imagen en su posición */
    background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
    z-index: 0;
  }


  .body-container-BackgroundImg-Banner-T {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #ebe9e9;
    background-position: center; /* Ajusta la posición de la imagen al centro */
    background-repeat: no-repeat;
    /*background-attachment: fixed;  Fija la imagen en su posición */
    background-size: cover; /* Cubre completamente el contenedor sin deformar la imagen */
    z-index: 0;
  }








/* Estilos para el contenido del banner */
.rounded-carousel-Main .carousel-inner {
    display: flex;
    flex-direction: row; /* Alinear los elementos en columna */
    width: 100%; /* Ancho del 80% de su contenedor */
    /* Máximo ancho fijo para mantener la legibilidad en pantallas muy anchas */
    background-color: #1f1f1f;
}







.custom-carousel-button {
    background-color: transparent; /* Color de fondo del botón */
    border: 2px solid white; /* Añadir un borde blanco al botón */
    border-radius: 50%; /* Hacer el botón circular */
    width: 40px; /* Ancho del botón */
    height: 40px; /* Altura del botón */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 15;
}


.custom-carousel-button-Pack {
    background-color: transparent; /* Color de fondo del botón */
    border: 2px solid #096a01; /* Añadir un borde blanco al botón */
    border-radius: 50%; /* Hacer el botón circular */
    width: 40px; /* Ancho del botón */
    height: 40px; /* Altura del botón */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 15;
}




.desktop-video-main video {
    width: 100%;
    object-fit: cover;
    margin-top: 0rem;
}



/* Titulo */
.Container-title-video{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 19%;
    margin-bottom: 0rem;
    width: 600px;
    z-index: 10;
}

.Container-title-video-N{
    display: flex;
    flex-direction: column;
    align-items: end;
    position: absolute;
    top: 30%;
    right: 15%;
    margin-bottom: 0rem;
    width: 47%;
    z-index: 10;
}



.title-basic-next-border-Pack{
    text-align: center;
    align-items: center;
    color: #efffea;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 68px;
    letter-spacing: 1.5px; 
    margin-top: 0.5rem;
    padding: 10px; /* Espacio entre el texto y el borde */
    background-color: #f0c612; /* Color de fondo oscuro */
    background-color: #096a01; /* Color de fondo oscuro */
    display: inline-block; /* Para que el fondo se ajuste al tamaño del texto */
}


.title-basic-next-border-Pack-N{
    text-align: end;
    align-items: end;
    color: #efffea;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 68px;
    letter-spacing: 1.5px; 
    margin-top: 0.5rem;
    margin-right: 1rem;
    padding: 10px; /* Espacio entre el texto y el borde */
    background-color: #f0c612; /* Color de fondo oscuro */
    background-color: #096a01; /* Color de fondo oscuro */
}



.Container-title-banner{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 55%;
    margin-bottom: 0rem;
    width: 600px;
    z-index: 500;
}


.title-video{
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
    color: #0e6806;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 69px;
    letter-spacing: 0.9px;
}

.title-video-N{
    display: flex;
    flex-direction: column;
    text-align: end;
    align-items: end;
    color: #0e6806;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 65px;
    line-height: 69px;
    letter-spacing: 0.9px;
}




.video-vid{
    display: flex;
    position: relative;
}
.img-Banner-Home-desktop{
    display: flex;
    position: relative;
}
.img-Banner-Home-movil{
    display: none;
}


/* Botones*/

.btn-outline-Home-container{
display: flex;
align-items: start;
}

.btn-outline-Home {
    display: inline-block;
    padding: 5px 12px;
    text-decoration-line: none;
    justify-content: start;
    align-items: center;
    outline: none;
    border: 2px solid yellow;
    background-color: yellow;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px;
    font-family: "Titillium Web", sans-serif;
    font-size: 0.94rem;
    font-weight: 700;
    color: #1f1f1f;
    transition: all 0.1s ease-out;
    filter: brightness(1.2);
}

.btn-outline-Home:hover {
    cursor: pointer;
    border: 2px solid rgb(255, 250, 223);
    background-color: rgb(255, 250, 223);
}



.img-Product{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 12%;
    right: 15%;
    width: 32rem;
    object-fit: cover;
    transition: all 0.2s linear;
    padding: 0rem;
  }

  .img-Product:hover{
    scale: 1.05;
  }








@media screen and (max-width: 1350px) {

    .rounded-carousel .carousel-inner {
        border-radius: 20px; /* Ajusta este valor según lo desees */
        overflow: hidden;
        width: 75rem;
        display: flex;
    }

}

@media screen and (max-width: 1300px) {

    .rounded-carousel .carousel-inner {
        border-radius: 20px; /* Ajusta este valor según lo desees */
        overflow: hidden;
        width: 70rem;
        display: flex;
    }
    

}



@media screen and (max-width: 1200px) {

    .rounded-carousel .carousel-inner {
        border-radius: 20px; /* Ajusta este valor según lo desees */
        overflow: hidden;
        width: 60rem;
        display: flex;
    }
    

}



@media screen and (max-width: 990px) {

    .rounded-carousel .carousel-inner {
        border-radius: 20px; /* Ajusta este valor según lo desees */
        overflow: hidden;
        width: 50rem;
        display: flex;
    }
    
}


@media screen and (max-width: 960px) {
    

    .carusel-center-main-Home {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 3rem;
        padding-bottom: 2rem;
        height: 100%;
    }


    .rounded-carousel .carousel-inner {
        border-radius: 20px; /* Ajusta este valor según lo desees */
        overflow: hidden;
        width: 100%;
        height: 30rem;
    }

    .video-vid{
        display: flex;
        position: absolute;
        height: 30rem;
    }
    .img-Banner-Home-desktop{
        display: none;
        position: relative;
    }
    .img-Banner-Home-movil{
        display: flex;
        position: absolute;
        height: 30rem;
    }


    .desktop-video-main video {
        width: 100%;
        object-fit: cover;
    }
    


    /* Titulo */
    .Container-title-video{
        display: flex;
        justify-content: center !important;
        position: relative !important;
        align-items: center;
        margin-bottom: 0rem;
        width: 100%;
        top: 0%;
        left: 0%;
        padding-top: 0%;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        z-index: 8;
    }

    .Container-title-video-N{
        display: flex;
        justify-content: center !important;
        position: relative !important;
        align-items: center;
        margin-bottom: 0rem;
        width: 100%;
        top: 0%;
        left: 0%;
        padding-top: 0%;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        z-index: 8;
    }




    .title-video{
        display: flex;
        text-align: center;
        align-items: center;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 25px !important;
        line-height: 30px;
        letter-spacing: 0px;
        width: 120%;
    }


    .title-video-N{
        display: flex;
        text-align: center;
        align-items: center;
        color: #ffffff;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 25px !important;
        line-height: 30px;
        letter-spacing: 0px;
        width: 90%;
    }

    .title-basic-next-border-Pack{
        text-align: center;
        align-items: center;
        color: #efffea;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 1.5px; 
        margin-top: 0.5rem;
        padding: 10px; /* Espacio entre el texto y el borde */
        background-color: #f0c612; /* Color de fondo oscuro */
        background-color: #096a01; /* Color de fondo oscuro */
        display: inline-block; /* Para que el fondo se ajuste al tamaño del texto */
    }

    .title-basic-next-border-Pack-N{
        text-align: center;
        align-items: center;
        color: #efffea;
        font-family: 'Protest Strike', sans-serif !important;
        font-size: 40px;
        line-height: 45px;
        letter-spacing: 1.5px; 
        margin-top: 0.5rem;
        padding: 10px; /* Espacio entre el texto y el borde */
        background-color: #f0c612; /* Color de fondo oscuro */
        background-color: #096a01; /* Color de fondo oscuro */
        display: inline-block; /* Para que el fondo se ajuste al tamaño del texto */
    }
    
    


    /* Agregar degradado negro en la parte inferior del video */
    .video-overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70%;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0));
        z-index: 50;
    }


    .btn-outline-Home {
        font-size: 0.78rem;
        font-weight: 700;
    }
    

    .img-Product{
        display: flex;
        justify-content: center;
        position: absolute;
        top: 10%;
        right: 0%;
        left: 12%;
        width: 15rem;
      }



      .custom-carousel-button {
        background-color: transparent; /* Color de fondo del botón */
        border: 2px solid white; /* Añadir un borde blanco al botón */
        border-radius: 50%; /* Hacer el botón circular */
        width: 30px; /* Ancho del botón */
        height: 30px; /* Altura del botón */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .custom-carousel-button-Pack {
        background-color: transparent; /* Color de fondo del botón */
        border: 2px solid #096a01; /* Añadir un borde blanco al botón */
        border-radius: 50%; /* Hacer el botón circular */
        width: 30px; /* Ancho del botón */
        height: 30px; /* Altura del botón */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .container-Harina-main{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }


    

    .Container-3d{
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
    .Container-3d-Movil{
        display: flex;
        justify-content: center;
        flex-direction: row;
    }




    .contaniner-info-Product{
        display: none;
    }
    
    .contaniner-info-Product-Movil{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 7rem;
        padding-bottom: 5rem;
    }
    
}