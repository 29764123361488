.btn_trayectory__container{ 
  display:flex; 
  margin-top: 4rem; 
} 
 
 
 
/*--------Trayectoria, Trabajo y Compromiso desde 1948-----------*/ 
 
.us-container-Movi{ 
  display: none; 
} 
 
.us-container__trayectory-movil{ 
  display: none !important; 
} 
 
 
.body-work-container{ 
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  padding-top: 2rem; 
  padding-bottom: 3rem; 
} 
 
/*Contenedor Secundario*/ 
.work-container { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  /* width: 80%; */ 
  margin: 0; 
  margin-top: 50px; 
  margin-bottom: 20px; 
} 
 
.us-wrap-Info-work { 
  position: relative; 
  display: inline-block; 
  flex-direction: column; 
  overflow: hidden; 
  width: 30rem; 
  max-width: 600px; 
  margin-top: 0rem; 
  right: 5rem; 
  text-align: center; 
  justify-content: center; 
  align-content: center; 
} 
 
.us-img-w { 
  display: block; 
  position: relative; 
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 0px; 
  transition: all 0.3s ease-in-out; 
  cursor: pointer; 
} 
 
.us-img-work { 
  display: block; 
  position: relative; 
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 0px; 
  transition: all 0.3s ease-in-out; 
  cursor: pointer; 
} 
 
.us-text-work{ 
  display: flex; 
  justify-content: end; 
} 
.text-basic-work { 
  text-align: end; 
  font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important; 
  margin-top: 1.3rem; 
  font-size: 15px; 
  letter-spacing: 0; 
  line-height: 23px; 
  width: 60%; 
} 
 
.us-text__trayectory{ 
  display: flex; 
  justify-content: start; 
} 
.text-basic__trayectory{ 
  text-align: start; 
  font-family: 'Protest Strike', sans-serif !important; 
  color: #04160c; 
  margin-top: 1.3rem; 
  font-size: 15px; 
  letter-spacing: 0; 
  line-height: 23px; 
  width: 60%; 
} 
 
.us-wrap-work{ 
    position: relative; 
    display: inline-block; 
    flex-direction: column; 
    width: 28rem; 
    margin-right: 4rem; 
    overflow: hidden; 
} 
 
 
 
 
 
 
 
.us-container__trayectory { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  width: 90%; 
  margin: 0; 
  margin-top: 7rem; 
  margin-bottom: 7rem; 
} 
 
 
.us-wrap__trayectory { 
  position: absolute; 
  display: inline-block; 
  flex-direction: column; 
  width: 50rem; 
  left: 0%; 
  overflow: hidden; 
  margin-left: 0rem !important; 
} 
 
.us-img__trayectory { 
  display: block; 
  position: relative; 
  left: 0%; 
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 0px; 
  transition: all 0.3s ease-in-out; 
  cursor: pointer; 
} 
 
.us-wrap-Info__trayectory{ 
  position: relative; 
  display: inline-block; 
  flex-direction: column; 
  overflow: hidden; 
  width: 37rem; 
  margin-top: 0rem; 
  text-align: center; 
  justify-content: center; 
  align-content: center; 
  left: 25rem; 
} 
 
 
.title-basic-trayectory{ 
  color: #000000; 
  font-family: "Open Sans","PingFang SC","Microsoft YaHei","Helvetica Neue","Hiragino Sans GB","WenQuanYi Micro Hei",Arial,sans-serif!important; 
  font-size: 33px; 
  font-weight: 700; 
  line-height: 40px; 
  letter-spacing: -1px; 
} 
 
 
 
 
 
 
.lottie-animation-tener {
  position: relative;
}

.lottie-animation-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  height: 500px;
  z-index: 20;
}




.us-img__trayectory { 
  position: relative; 
  z-index: 2; /* Asegura que la imagen esté por encima de la animación */ 
} 
 
 
 
 
@media only screen and (max-width: 960px) { 
 
  .us-container__trayectory{ 
    display:none; 
  } 
 
 
.work-container { 
  display: none; 
} 
 
.body-work-container { 
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  padding-top: 0rem; 
} 
 
.us-wrap-work { 
  display: flex; 
  justify-content: center; 
  width: 100%; 
  margin-top: 1rem; 
  margin-left: 0rem; 
} 
 
.us-img-work { 
  display: flex; 
  justify-content: center; 
  width: 90% !important; 
  height: 100%; 
  object-fit: cover; 
  border-radius: 0px; 
  transition: all 0.3s ease-in-out; 
  cursor: pointer; 
} 
 
 
 
 
 
 
.us-container__trayectory-movil{ 
  display: flex !important; 
  flex-direction: column; 
  justify-content: center !important; 
  flex-direction: column !important; 
  margin-top: 3rem !important; 
  width: 100% !important; 
  padding-bottom: 0rem; 
} 
 
.us-wrap__trayectory-movil { 
  display: flex !important; 
  justify-content: center !important; 
  width: 100% !important; 
  margin-top: 4rem; 
  margin-left: 0rem !important; 
   
} 
 
.us-img__trayectory { 
  display: flex !important; 
  width: 100% !important; 
  height: 100%; 
} 
 
.us-wrap-Info__trayectory { 
  display: flex !important; 
  justify-content: center !important; 
  width: 100% !important; 
  margin-top: 1rem; 
  left: 0rem !important; 
  padding-left: 0.8rem; 
  padding-right: 0.8rem; 
} 
 
.title-basic-trayectory{ 
  text-align: center !important; 
  margin-top: 2rem !important; 
} 
 
 
.text-basic__trayectory { 
  text-align: center !important; 
  justify-content: center; 
  width: 95% !important; 
  margin: 0px; 
  margin-top: 1rem; 
} 
 
 
 
 
.us-text__trayectory{ 
  display: flex; 
  justify-content: center; 
} 
 
}
































