.Container__LineComponent__Main{
  display: flex;
  justify-content: center;
}

.LineContainer{
  padding-top: 5rem;
  padding-bottom: 4rem;
}


/*Linea Vertical*/

.vertical-timeline::before {
    background: #7cdf0a; /* Cambia el color de la línea vertical #062614 */
    width: 0px; /* Cambia el grosor de la línea vertical */
  }
  
  .vertical-timeline::after {
    background: #7cdf0a; /* Cambia el color de la línea horizontal */
  }
  
  .vertical-timeline-element-content {
    border-color: #7cdf0a; /* Cambia el color de los bordes de cada elemento */
  }





/* Este es elcontenedor de la linea de Tiempo*/
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.3s;
    animation: cd-bounce-2 0.3s;
    box-shadow: 0 0 30px 3px #32ff8b0a;
  }
.vertical-timeline-element-content.bounce-in {
 /* transition: box-shadow 0.5s ease;*/
  }
.vertical-timeline-element-content.bounce-in:hover {
  /*box-shadow: 0 0 30px 3px #00000048;*/
}


  /*Icono*/


  .vertical-timeline-element-icon {
    width: 40px; /* Ancho del círculo */
    height: 40px; /* Alto del círculo */
    line-height: 40px; /* Alineación vertical del icono */
    border: none !important; 

}

  .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in{
    width: 50px; /* Ancho del círculo */
    height: 50px; /* Alto del círculo */
    line-height: 0px; /* Alineación vertical del icono */
    border-radius: 30%; /* Asegura que el borde forme un círculo */
    border: none !important; 
}
  .vertical-timeline-element-icon svg {
    font-size: 20px; /* Cambia el tamaño del icono */
  }




  /* Titulos y texto*/
.title-basic-company{
    text-align: center;
    align-items: center;
    color: #ffffff;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 35px;
    line-height: 38px;
    letter-spacing: 0.5px;
    text-decoration-color: #e7eb22;
    margin-top: 2rem;
  }

  .subtitle-basic-company{
    text-align: start;
    align-items: center;
    color: #ffffff;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.9px;
    text-decoration-color: #e7eb22;
    margin-top: 2rem;
  }

  .text-basic-company{
    text-align: center;
    align-items: center;
    color: #ffffff ;
    font-family: "Titillium Web", sans-serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.8px;
    text-decoration-color: #e7eb22;
    margin-top: 1.5rem;
  }


/* Fecha
.vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
  position: absolute;
  color: #ffffff;
  font-family: "Titillium Web", sans-serif;
  font-size: 17px;
  width: 100%;
  left: 124%;
  top: 6px;
  font-size: 16px;
  font-size: 1rem;
}
*/
.vertical-timeline-element-content .vertical-timeline-element-date {

  font-family: "Titillium Web", sans-serif !important;
  font-size: 18px !important;
  letter-spacing: 0.5px;
  font-weight: 700;
  padding-top: 0.3rem;

}









  .vertical-timeline-element:after {
    content: "";
    display: table;
    clear: both;
    color: #001308;
    --line-color: #f16363 !important;
}


.imgTimeLine{
    width: 460px;
}


/**/
.title-basic-TimeLine-main{
  text-align: center;
  align-items: center;
  color: #ffffff;
  font-family: 'Protest Strike', sans-serif !important;
  font-size: 65px;
  line-height: 68px;
  letter-spacing: 1.5px;
  text-decoration-color: #e7eb22;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 11rem;
  margin-bottom: 5rem;
}




@media screen and (max-width: 960px) {



      /* Titulos y texto*/
.title-basic-company{
    text-align: start;
    align-items: center;
    color: #ffffff;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 45px;
    line-height: 45px;
    letter-spacing: 0.5px;
    text-decoration-color: #e7eb22;
    margin-top: 2rem;
  }

  .subtitle-basic-company{
    text-align: start;
    align-items: center;
    color: #ffffff;
    font-family: 'Protest Strike', sans-serif !important;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.9px;
    text-decoration-color: #e7eb22;
    margin-top: 2rem;
  }

  .text-basic-company{
    text-align: start;
    align-items: center;
    color: #ffffff;
    font-family: "Titillium Web", sans-serif;
    font-size: 17px !important;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-decoration-color: #e7eb22;
    margin-top: 2rem;
  }






    .imgTimeLine{
        width: 300px;
    }

    .vertical-timeline-element-icon {
        margin-left: 0.5rem;
      }

    .vertical-timeline--animate .vertical-timeline-element-icon.bounce-in{
        width: 30px; /* Ancho del círculo */
        height: 30px; /* Alto del círculo */
        line-height: 0px; /* Alineación vertical del icono */
        border-radius: 30%; /* Asegura que el borde forme un círculo */
        
    }
      .vertical-timeline-element-icon svg {
        font-size: 15px; /* Cambia el tamaño del icono */
      }


      /* Fecha*/
      .vertical-timeline-element-content .vertical-timeline-element-date {
        float: left;
        padding: 0.8em 0;
        opacity: .7;
        font-family: "Titillium Web", sans-serif !important;
        font-size: 15px;
      }

    
}

